export const STORAGE_PATH = "https://storage.googleapis.com/fdswe-bucket";
export const paymentMethod = {
  // CASH_ON_DELIVERY: {
  //   label: "Cash on Delivery",
  //   value: "CASH_ON_DELIVERY",
  // },
  CARD: {
    label: "Credit Card",
    value: "CARD",
  },
};
