import { LOGO } from "assets/img";
import { paymentMethod } from "constant";
import React, { useEffect, useMemo, useState, forwardRef } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Nav,
  OverlayTrigger,
  Row,
  Spinner,
  Tab,
  Tooltip,
} from "react-bootstrap";
import Icofont from "react-icofont";
import DatePicker from "react-datepicker";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { offerService, orderService, userService } from "services";
import { useModalStore } from "store";
import { cartState, cartTotalState } from "store/product";
import { locationsState, userCardsState } from "store/user";
import { cardOffersState } from "store/offer";
import CheckoutItem from "./common/CheckoutItem";
import ChooseAddressCard from "./common/ChooseAddressCard";
import ChooseDeliveryOptionCard from "./common/ChooseDeliveryOptionCard";
import ItemsCarousel from "./common/ItemsCarousel";
import PaymentCard from "./common/PaymentCard";
import AddAddressModal from "./modals/AddAddressModal";
import ConfirmCardModal from "./modals/ConfirmCardModal";
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from "react-router-dom";
import Loading from "./common/Loading";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { freeDeliveryAreasListState } from "store/meta";

const getLocationIcon = (tag = "") => {
  switch (tag) {
    case "Home":
      return "ui-home";
    case "Work":
      return "briefcase";
    default:
      return "location-pin";
  }
};

const CustomDateInput = forwardRef(({ value, onClick, onChange }, ref) => (
  <div style={{ width: "100%" }}>
    <Form.Label>Delivery Date</Form.Label>
    <InputGroup ref={ref}>
      <Form.Control
        type="text"
        placeholder="Select Date"
        onChange={onChange}
        value={value}
      />
      <InputGroup.Append>
        <Button variant="outline-secondary" onClick={onClick}>
          <Icofont icon="calendar" />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  </div>
));

const Checkout = ({ history }) => {
  const showModal = useModalStore((state) => state.showModal);
  const [cart, setCart] = useRecoilState(cartState);
  const resetCart = useResetRecoilState(cartState);
  const cartSubTotal = useRecoilValue(cartTotalState);
  const [locations, setLocations] = useRecoilState(locationsState);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState(null);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [deliveryTimeSlots, setDeliveryTimeSlots] = useState([]);
  const [placingOrder, setPlacingOrder] = useState(false);
  const [selectedPromoCode, setSelectedPromoCode] = useState();
  const [loadingAddrresses, setLoadingAddresses] = useState(false);
  const [timeSlot, setTimeSlot] = useState("");
  const [userCards, setUserCards] = useRecoilState(userCardsState);
  const [cardOffers, setCardOffers] = useRecoilState(cardOffersState);
  const [loadingCards, setLoadingCards] = useState(false);
  const [specialIns, setSpecialIns] = useState("");

  const [cardOfferDiscount, setCardOfferDiscount] = useState(0);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    paymentMethod.CARD.value
  );

  const [discountinfo, setDiscountInfo] = useState({
    sub_total: 0,
    discount: 0,
    grand_total: 0,
  });

  const freeDeliveryAreas = useRecoilValue(freeDeliveryAreasListState);

  const isPayable = () => {
    const hasDeliverySlot = deliveryDate && deliveryTimeSlots && timeSlot !== "";
    if (selectedDeliveryOption == 1) {
      return hasDeliverySlot && ((selectedPaymentMethod === paymentMethod.CARD.value && selectedCardId));
      // return hasDeliverySlot && ((selectedPaymentMethod === paymentMethod.CARD.value && selectedCardId) || selectedPaymentMethod === paymentMethod.CASH_ON_DELIVERY.value);
    }

    if (selectedPaymentMethod === paymentMethod.CARD.value) {
      return hasDeliverySlot && selectedLocationId && selectedCardId;
    }

    return hasDeliverySlot && selectedLocationId;
  };

  const deliveryFee = useMemo(() => {
    if (!selectedLocationId) {
      return 0;
    }

    return locations[selectedLocationId].delivery_area.price;
  }, [selectedLocationId]);

  const cartTotal = useMemo(() => {
    return cartSubTotal;
  });

  const handleAddToCart = (id, option, potion, quantity, total) => {
    const itemId = `${id}.${option}.${potion}`;
    const updatedItem = {
      quantity,
      total,
    };
    setCart({
      ...cart,
      [itemId]: {
        ...cart[itemId],
        ...updatedItem,
      },
    });
  };

  const handleRemoveCartItem = (id, potion, option) => {
    const itemId = `${id}.${potion}.${option}`;
    let clone = JSON.parse(JSON.stringify(cart));
    delete clone[itemId];
    setCart({ ...clone });
  };

  const handleLocationSelect = (id) => {
    setSelectedLocationId(id);
  };

  const handleDeliveryOption = (id) => {
    setSelectedDeliveryOption(id);
    setSelectedLocationId(null);
  };

  const handleAddressCreateModal = () => {
    showModal(
      <AddAddressModal
        btnName="ADD"
        defaultValues={{ coords: {} }}
        title="Add Delivery Address"
      />
    );
  };

  const createOrder = async () => {
    const product_data = Object.values(cart).map((item) => {
      return {
        product: item.id,
        quantity: item.quantity,
        product_portion: item.potionId,
        product_option: item.optionId,
        note: "",
      };
    });

    const location_id = selectedLocationId;
    const payment_method = selectedPaymentMethod;
    const order_date = deliveryDate;
    const time_slot = timeSlot;
    const card_id = selectedCardId;

    const order = {
      product_data,
      location_id,
      payment_method,
      order_date,
      time_slot,
      card_id,
      promo_code: selectedPromoCode,
      note: specialIns
    };

    try {
      setPlacingOrder(true);
      const data = await orderService.createOrder(order);
      resetCart();
      if (data && data.payment_status && data.payment_status === "REJECTED")
        history.push("error");
      else
        history.push("success");

    } catch (error) {
      throw error;
    } finally {
      setPlacingOrder(false);
    }
  };

  let cardBinNumbers = [];
  let cardOffersList = {};
  const [stateBinNumbers, setStateBinNumbers] = useState([]);
  const [stateofferList, setStateBinNumbersOfferList] = useState({});
  const [stateOfferMaxCaps, setStateOfferMaxCaps] = useState({});
  async function fetchData() {
    try {
      setLoadingAddresses(true);
      setLoadingCards(true);
      const locations = await userService.getLocations();
      const userCards = await userService.getUserCards();
      const cardOffers = await offerService.getCardOffers();
      setUserCards(userCards);
      setLocations(locations);

      cardOffers.forEach((cardOffer) => {
        if (cardOffer.bin_numbers !== null && cardOffer.bin_numbers !== "") {
          cardOffer.bin_numbers.split(",").forEach((binNumber) => {

            let newOfferList = stateofferList;
            newOfferList[binNumber] = cardOffer.percentage;
            setStateBinNumbersOfferList(newOfferList);

            let newStateOfferMaxCaps = stateOfferMaxCaps;
            newStateOfferMaxCaps[binNumber] = cardOffer.maxCap;
            setStateOfferMaxCaps(newStateOfferMaxCaps);

            let newBinNumbers = stateBinNumbers;
            newBinNumbers.push(binNumber);
            setStateBinNumbers(newBinNumbers);
          });
        }
      });
    } catch (error) {
    } finally {
      setLoadingAddresses(false);
      setLoadingCards(false);
    }
  }

  const format2 = "YYYY-MM-DD";

  let dateformat = moment(deliveryDate).format(format2);

  const datetype = "T00:00:00.000Z";

  async function fetchDeleveryTimeSlots(deliveryDate) {
    try {
      const data = await orderService.getDeliveryTimeSlots({
        from: dateformat,
      });
      setDeliveryTimeSlots(data);
    } catch (error) {
      throw error;
    }
  }

  const PaymentButton = () => {
    return (
      <Button
        onClick={() => createOrder()}
        variant="success"
        disabled={!isPayable() || placingOrder}
        className="btn-success btn-block btn-lg"
      >
        {placingOrder ? (
          <Spinner
            as="span"
            animation="border"
            size="md"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <div>
            {/* PAY Rs. {cartTotal} wewew */}
            {discountinfo.discount === 0 ? (
                      <div> PAY Rs.{cartTotal - cardOfferDiscount + deliveryFee}</div>
                    ) : (
                      <div> PAY Rs.{discountinfo.grand_total - cardOfferDiscount + deliveryFee} </div>
                    )}

            {/* {discountinfo.discount === 0 ? (
              <div>PAY Rs.{cartTotal}</div>
            ) : (
              <div>PAY Rs.{discountinfo.grand_total} </div>
            )} */}
            {/* <Icofont icon="long-arrow-right" /> */}
          </div>
        )}
      </Button>
    );
  };

  useEffect(() => {
    fetchDeleveryTimeSlots(deliveryDate);
  }, [deliveryDate]);

  useEffect(() => {
    fetchData();
  }, []);

  let carditem = [];


  const onSubmitDiscount = async (values) => {
    if(selectedPromoCode == values.promocode){
      return false;
    }

    setSelectedPromoCode(values.promocode)
    const data = {
      product_data: carditem,
      promocode: values.promocode,
    };
    //todo extra validations

    try {
      const discountdata = await userService.discount(data);

      const info = {};

      info.sub_total = discountdata.sub_total;
      info.discount = discountdata.discount;
      info.grand_total = discountdata.grand_total;
      
      setDiscountInfo({...discountinfo, sub_total: info.sub_total, discount: info.discount, grand_total: info.grand_total});

      // setDiscountInfo(info);
      toast("Promotion successfully applied");
    } catch (err) {
      //show error toast
    }
  };

  async function createCardConf() {
    showModal(
      <ConfirmCardModal
        title="Confirm & Proceed"
        message={`By confirming this, you are giving consent to save your card details and authorize a charge of Rs. 100.00 as a transaction validation fee. Upon confirmation, you will receive a one-time CashBack coupon worth Rs. 100.00.`}
        onConfirm={createCard}
      />
    );
  }

  async function createCard() {
    const cardRequestDetails = await userService.addCard();

    window.location = cardRequestDetails.paymentPageUrl;
  }

  useEffect(() => {
    const findCardInfo = userCards.find((rec) => rec.id == selectedCardId);

    if (findCardInfo && (stateofferList[
      findCardInfo.bin_number.substring(0, 4)
    ] || stateofferList[
      findCardInfo.bin_number.substring(0, 6)
      ])) {

      const offer = stateofferList[
        findCardInfo.bin_number.substring(0, 4)
      ] ? stateofferList[
      findCardInfo.bin_number.substring(0, 4)
      ] : stateofferList[
      findCardInfo.bin_number.substring(0, 6)
      ];

      const maxCap = stateOfferMaxCaps[
        findCardInfo.bin_number.substring(0, 4)
      ] ? stateOfferMaxCaps[
      findCardInfo.bin_number.substring(0, 4)
      ] : stateOfferMaxCaps[
      findCardInfo.bin_number.substring(0, 6)
      ];

      if(discountinfo.discount != 0 && discountinfo.grand_total != 0){
        let disc = discountinfo.grand_total * (Number(offer) / 100);
        if(maxCap && maxCap > 0 && maxCap < disc) {
          disc = maxCap;
        }
        setCardOfferDiscount(disc);
      } else{
        let disc = cartTotal * (Number(offer) / 100);
        if(maxCap && maxCap > 0 && maxCap < disc) {
          disc = maxCap;
        }
        setCardOfferDiscount(disc);
      }
    } else{
      setCardOfferDiscount(0);
    }

  }, [selectedCardId, JSON.stringify(cart)]);

  return (
    <section className="offer-dedicated-body mt-4 mb-4 pt-2 pb-2">
      <Container>
        <Row>
          <Col md={8}>
            <div className="offer-dedicated-body-left">
              <div className="bg-white rounded shadow-sm p-4 mb-4">
                <h6 className="mb-3">Most popular</h6>
                <ItemsCarousel />
              </div>

              <div className="pt-2" />

              <div className="bg-white rounded shadow-sm p-4 mb-4">
                <Row className="mb-2">
                  <Col md={12}>
                    <h4 className="mb-1 float-left">
                      Choose delivery option
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <ChooseDeliveryOptionCard
                      selected={1 == selectedDeliveryOption}
                      id={1}
                      boxclassName="border border-success"
                      title={'Pickup at Bakery'}
                      icoIcon={getLocationIcon('')}
                      iconclassName="icofont-3x"
                      onDeliverHere={handleDeliveryOption}
                    />
                  </Col>
                  <Col md={6}>
                    <ChooseDeliveryOptionCard
                      selected={2 == selectedDeliveryOption}
                      id={2}
                      boxclassName="border border-success"
                      title={'Delivery'}
                      icoIcon={getLocationIcon('')}
                      iconclassName="icofont-3x"
                      onDeliverHere={handleDeliveryOption}
                    />
                  </Col>
                </Row>

                {selectedDeliveryOption == 2 && (
                  <>
                    <Row className="mb-2">
                      <Col md={12}>
                        <h4 className="mb-1 float-left">
                          Choose a delivery address
                        </h4>
                        <div className="float-right">
                          <Button onClick={handleAddressCreateModal} size="sm">
                            ADD DELIVERY ADDRESS
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {loadingAddrresses ? (
                        <Col
                          md={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Loading />{" "}
                        </Col>
                      ) : null}
                    </Row>
                    <Row>
                      {Object.values(locations).map((location, index) => {
                        const address = `${location.address_line_1}, ${location.address_line_2}`;
                        return (
                          <Col key={index} md={6}>
                            <ChooseAddressCard
                              selected={location.id == selectedLocationId}
                              id={location.id}
                              boxclassName="border border-success"
                              title={location.tag}
                              icoIcon={getLocationIcon(location.tag)}
                              price={location.delivery_area.price}
                              city={location.delivery_area.name}
                              iconclassName="icofont-3x"
                              onDeliverHere={handleLocationSelect}
                              address={address}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </>
                )}

              </div>

              <div className="pt-2" />

              <div className="bg-white rounded shadow-sm p-4 mb-4 osahan-payment">
                <h4 className="mb-1">Choose Delivery Date & Time</h4>
                <div className="pt-2" />
                <Row>
                  <Col sm={12} md={6}>
                    <DatePicker
                      className="col-md-12"
                      selected={deliveryDate}
                      dateFormat="dd MMM, yyyy"
                      minDate={moment().toDate()}
                      onChange={(date) => setDeliveryDate(date)}
                      customInput={<CustomDateInput />}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <Form.Group className="col-md-12">
                      <Form.Label>Delivery Time</Form.Label>
                      <Form.Control
                        as="select"
                        placeholder="Delivery Time"
                        value={timeSlot}
                        onChange={(event) => setTimeSlot(event.target.value)}
                        disabled={deliveryTimeSlots.length < 1}
                      >
                        <option value={""}>Select Delivery Time</option>
                        {deliveryTimeSlots.map((slot, index) => (
                          <option key={index} value={slot}>
                            {slot}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className="pt-2" />

              <div className="bg-white rounded shadow-sm p-4 osahan-payment">
                <h4 className="mb-1">Choose payment method</h4>
                <div className="pt-2" />
                {/* <h6 className="mb-3 text-black-50">
                  Pay by Card / Cash on Delivery
                </h6> */}
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col sm={4} className="pr-0">
                      <Nav variant="pills" className="flex-column">
                        <Nav.Link
                          eventKey="first"
                          onClick={() =>
                            setSelectedPaymentMethod(paymentMethod.CARD.value)
                          }
                        >
                          <Icofont icon="credit-card" /> Credit/Debit Cards
                        </Nav.Link>
                        {/* <Nav.Link
                          eventKey="fifth"
                          onClick={() =>{
                            setSelectedPaymentMethod(
                              paymentMethod.CASH_ON_DELIVERY.value
                            )
                            setSelectedCardId(null);
                          }}
                        >
                          <Icofont icon="money" /> Pay on Delivery
                        </Nav.Link> */}
                      </Nav>
                    </Col>
                    <Col sm={8} className="pl-0">
                      <Tab.Content className="h-100">
                        <Tab.Pane eventKey="first">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <h6 className="mb-3 mt-0 mb-3">Pay by card</h6>
                            <Button onClick={createCardConf} size="sm">
                              Add Card
                            </Button>
                          </div>
                          <p>
                            We Accept{" "}
                            <span className="osahan-card">
                              <Icofont icon="visa-alt" />{" "}
                              <Icofont icon="mastercard-alt" />{" "}
                              <Icofont icon="american-express-alt" />
                            </span>
                          </p>
                          {loadingCards ? (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Loading />
                            </div>
                          ) : null}
                          {userCards.map((card, index) => {
                            if (
                              stateBinNumbers.includes(
                                card.bin_number.substring(0, 4)
                              ) || stateofferList[
                              card.bin_number.substring(0, 6)
                              ]
                            ) {
                              return (
                                <PaymentCard
                                  key={index}
                                  type={card.type}
                                  title={card.cardNumber}
                                  logoImage="img/bank/1.png"
                                  imageclassName="mr-3"
                                  subTitle={`VALID TILL ${card.validity}`}
                                  onSelect={() => setSelectedCardId(card.id)}
                                  selected={selectedCardId === card.id}
                                  offer={
                                    stateofferList[
                                    card.bin_number.substring(0, 4)
                                    ] || stateofferList[
                                    card.bin_number.substring(0, 6)
                                    ]
                                  }
                                  expired={false}
                                />
                              );
                            } else {
                              return (
                                <PaymentCard
                                  key={index}
                                  type={card.type}
                                  title={card.cardNumber}
                                  logoImage="img/bank/1.png"
                                  imageclassName="mr-3"
                                  subTitle={`VALID TILL ${card.validity}`}
                                  onSelect={() => setSelectedCardId(card.id)}
                                  selected={selectedCardId === card.id}
                                  offer={""}
                                  expired={false}
                                />
                              );
                            }
                          })}
                          <hr />
                          <PaymentButton />
                        </Tab.Pane>
                        <Tab.Pane eventKey="fifth">
                          <h6 className="mb-3 mt-0 mb-3">Cash</h6>
                          <p>
                            Please keep exact change handy to help us serve you
                            better
                          </p>
                          <hr />
                          <Form>
                            <PaymentButton />
                          </Form>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item">
              <div className="d-flex mb-4 osahan-cart-item-profile">
                <Image
                  fluid
                  className="mr-3 rounded-pill"
                  alt="watersedge"
                  src={LOGO}
                />
                <div className="d-flex flex-column">
                  <h6 className="mb-1 text-white">Waters Edge</h6>
                  <p className="mb-0 text-white">
                    <Icofont icon="location-pin" /> 316, ETHUL KOTTE ROAD,
                    BATTARAMULLA
                  </p>
                </div>
              </div>
              <div className="bg-white rounded shadow-sm mb-2">
                {Object.values(cart).map((item, index) => {
                  carditem.push({
                    product_id: item.id,
                    quantity: item.quantity,
                    portion_id: item.potionId,
                    option_id: item.optionId,
                  });

                  return (
                    <CheckoutItem
                      key={index}
                      itemName={item.name}
                      price={item.price}
                      priceUnit="Rs."
                      id={item.id}
                      optionId={item.optionId}
                      optionName={item.optionName}
                      potionId={item.potionId}
                      potionName={item.potionName}
                      quantity={item.quantity}
                      show={true}
                      onRemove={handleRemoveCartItem}
                      onAdd={handleAddToCart}
                    />
                  );
                })}
              </div>
              <div className="mb-2 bg-white rounded p-2 clearfix">
                <Formik
                  initialValues={{
                    promocode: "",
                  }}
                  validationSchema={Yup.object().shape({
                    promocode: Yup.string().required("Required"),
                  })}
                  onSubmit={onSubmitDiscount}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    touched,
                    values,
                    submitForm,
                    isValid,
                  }) => (
                    <InputGroup className="input-group-sm mb-2">
                      <Form.Control
                        name="promocode"
                        id="promocode_id"
                        type="text"
                        placeholder="Enter promo code"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.promocode}
                      />
                      <InputGroup.Append>
                        <Button
                          variant="primary"
                          id="button-addon2"
                          // type="submit"
                          onClick={submitForm}
                        >
                          <Icofont icon="sale-discount" /> APPLY
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  )}
                </Formik>
                {/* <InputGroup className="input-group-sm mb-2">
                  <Form.Control type="text" placeholder="Enter agent code" />
                  <InputGroup.Append>
                    <Button variant="primary" type="button" id="button-addon2">
                      <Icofont icon="sale-discount" /> APPLY
                    </Button>
                  </InputGroup.Append>
                </InputGroup> */}
                <InputGroup className="mb-0">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <Icofont icon="comment" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    as="textarea"
                    placeholder="Special delivery and order instructions"
                    aria-label="With textarea"
                    name="specialIns"
                    id="specialIns"
                    type="text"
                    value={specialIns}
                    onChange={(event) => setSpecialIns(event.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="mb-2 bg-white rounded p-2 clearfix">
                <p className="mb-1">
                  Item Total{" "}
                  <span className="float-right text-dark">
                    Rs. {cartSubTotal}
                  </span>
                </p>
                <p className="mb-1">
                  Taxes <span className="float-right text-dark">Rs. 0.0</span>
                </p>
                <p className="mb-1">
                  Delivery Fee
                  <span className="float-right text-dark">
                    Rs. {deliveryFee}
                  </span>
                </p>
                <p className="mb-1 text-success">
                  Total Discount
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">Total discount breakup</Tooltip>
                    }
                  >
                    <span className="text-info ml-1">
                      <Icofont icon="info-circle" />
                    </span>
                  </OverlayTrigger>
                  <span className="float-right text-success">
                    Rs. {discountinfo.discount + cardOfferDiscount}
                  </span>
                </p>
                <hr />
                <h6 className="font-weight-bold mb-0">
                  TO PAY{" "}
                  <span className="float-right">
                    {discountinfo.discount === 0 ? (
                      <div> Rs.{cartTotal - cardOfferDiscount + deliveryFee}</div>
                    ) : (
                      <div> Rs.{discountinfo.grand_total - cardOfferDiscount + deliveryFee} </div>
                    )}
                  </span>
                  {/* TO PAY <span className="float-right">Rs. {cartTotal}</span> */}
                </h6>
              </div>
              {/* <Button
                variant="success"
                disabled={!selectedLocationId}
                className="btn-success btn-block btn-lg"
              >
                PAY Rs. {cartTotal}
                <Icofont icon="long-arrow-right" />
              </Button> */}
            </div>
            {/* <div className="pt-2"></div>
					<div className="alert alert-success" role="alert">
					   You have saved <strong>Rs.1,884</strong> on the bill
					</div>
					   <div className="pt-2"></div>
					   <div className="text-center pt-2">
						   <Image fluid src="https://dummyimage.com/352x504/ccc/ffffff.png&text=Google+ads" />
					   </div> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default withRouter(Checkout);
